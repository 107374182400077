import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import File from "../../file";
import { useMapStore } from "../../../store/map";
import { CONTROL_SIGNALER_ERROR } from "../../map/constant";

export default function ErrAddress() {
    const data = useStaticQuery(graphql`
        query {
            directus {
                contents(
                    filter: {
                        code: { code: { _eq: "service-erreur-adresse" } }
                        page: { code: { _eq: "service" } }
                    }
                ) {
                    text
                    id
                    file {
                        imageFile {
                            publicURL
                        }
                        id
                    }
                }
            }
        }
    `);

    const { setClose, setControl } = useMapStore();
    const activeNewAdresse = () => {
        setControl(CONTROL_SIGNALER_ERROR);
    };
    const contents = data.directus.contents;
    let content;
    if (contents.length) {
        content = contents[0];
    }

    const btn_style = {
        width: "calc(25% - 10px)",
    };

    return (
        <div className="tw-flex tw-flex-wrap tw-h-[430px]">
            <div
                className="tw-hidden sm:tw-w-5/12 sm:tw-flex tw-justify-center tw-items-center tw-h-[430px]"
                style={{ backgroundColor: "#7B7490" }}
            >
                <File
                    filename={content?.file?.imageFile?.publicURL}
                    className="tw-h-[430px]"
                />
            </div>
            <div
                className="tw-p-3 tw-w-full sm:tw-w-7/12 tw-flex tw-flex-col tw-justify-center"
                style={{ backgroundColor: "#EC6D1D" }}
            >
                <div
                    className="tw-p-5 tw-text-white"
                    dangerouslySetInnerHTML={{ __html: content?.text }}
                ></div>
                <div className="tw-flex tw-flex-col sm:tw-flex sm:tw-justify-between sm:tw-flex-row tw-px-5 tw-pb-2">
                    <button
                        onClick={() => {
                            setClose(true);
                            activeNewAdresse();
                        }}
                        className="tw-btn tw-border-0 tw-btn-sm tw-font-normal  tw-mt-3 tw-w-full tw-text-xs sm:tw-mt-8 sm:tw-w-48 sm:tw-text-sm lg:tw-px-4 tw-rounded-xl tw-text-white tw-bg-primary hover:tw-bg-secondary_hover"
                    >
                        <Link
                            to="/"
                            className="tw-text-white tw-no-underline hover:tw-text-white"
                        >
                            ACCEDER A LA CARTE
                        </Link>
                    </button>
                    <button className="tw-btn  tw-border-0 tw-btn-sm tw-font-normal tw-mt-3 tw-w-full tw-text-xs sm:tw-mt-8 sm:tw-w-48 sm:tw-text-sm lg:tw-px-4 tw-rounded-xl tw-text-white tw-bg-primary hover:tw-bg-secondary_hover">
                        <Link
                            to="/_services/erreur_adresse"
                            className="tw-text-white tw-no-underline hover:tw-text-white"
                        >
                            SIGNALER UNE ERREUR
                        </Link>
                    </button>
                </div>
            </div>
        </div>
    );
}
