import React from "react";
import Layout from "../../layouts/layout";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import DmdAddress from "../../components/services/address/demande";
import NewAddress from "../../components/services/address/newaddress";
import ErrAddress from "../../components/services/address/err_address";
import Infos from "../../components/services/address/infos";
import { faqs } from "../../mock/faqs";

const services = () => {
    return (
        <Layout>
            <div className="tw-bg-bgColor">
                <NewAddress />

                {/* <DmdAddress /> */}

                <ErrAddress />

                <Infos />

                <div className=" tw-px-4 sm:tw-px-10 tw-pt-7 tw-pb-5">
                    <div className="">
                        <p className="tw-text-2xl sm:tw-text-2xl tw-font-bold tw-text-primary">
                            FAQs
                        </p>
                        <div className="tw-w-11/12">
                            {faqs.map((item: any, key: number) => {
                                return (
                                    <Disclosure
                                        as="div"
                                        className="tw-my-5"
                                        key={key}
                                    >
                                        {({ open }) => (
                                            <>
                                                <div className="tw-flex tw-justify-between">
                                                    <Disclosure.Button className="tw-flex tw-w-full tw-justify-between tw-text-left tw-text-sm tw-font-medium tw-border-b-2">
                                                        <span>
                                                            {/* {key + 1}°/{" "} */}
                                                            {item.question}
                                                        </span>
                                                        <ChevronUpIcon
                                                            className={`${
                                                                open
                                                                    ? "tw-rotate-180 tw-transform"
                                                                    : "tw-rotate-90 tw-transform"
                                                            } tw-h-7 tw-w-7 tw-text-orange-500`}
                                                        />
                                                    </Disclosure.Button>
                                                </div>
                                                <Disclosure.Panel className="tw-px-0 tw-pt-4 tw-pb-2 tw-text-sm tw-text-gray-500">
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: item.response,
                                                        }}
                                                    />
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default services;
