export const faqs: { question: string; response: string }[] = [
    {
        question: "Que signifie le numéro poché à mon entrée ?",
        response: `Le numéro correspond au numéro d’adresse qui est attribué à l’entrée de votre unité d’occupation ou construction.
            Ce numéro est métrique et correspond à la distance métrique de l’entrée par rapport au début de la voie. Il est pair du côté droit de la voie et impair du côté gauche.
            Ce numéro complété du nom de la voie et de la localite correspond à l’adresse officielle de l’unité d’occupation.`,
    },
    {
        question: "A quoi va me servir l’adresse ?",
        response: `L’adresse va vous permettre de localiser plus précisément à moins d’un mètre le lieu de votre domicile, votre commerce ou pour une entreprise votre siège social. Elle vous permet également de fournir une meilleure information aux services de secours et aux distributeurs de biens et de services (taxis, livreur, etc…)`,
    },
    {
        question: "Pourquoi j’ai un voisin qui a le même numéro que moi ?",
        response: `Un voisin peut avoir le même numéro que vous mais il ne sera pas dans la même rue que vous. L’adresse est constituée du numéro mais aussi du nom de la rue pour être unique au sein d’une localite. `,
    },
    {
        question: "Quel est le nom de ma rue ?",
        response: `Le nom de la rue répond à un processus de dénomination officielle engagé par la Capec en lien avec les communes ou le district et validé par le Gouvernement. Dès lors que cette dénomination officielle est validée, il est prévu le panneautage de la rue à chaque carrefour correspondant.`,
    },
    {
        question: "Pourquoi le numéro n’a pas été poché à mon entrée ?",
        response: `Les pocheurs passent systématiquement pour apposer les numéros d’adresse devant chaque unité d’occupation. Si le numéro n’a pas été poché c’est soit qu’il y avait une impossibilité physique (végétation, clôture, etc…) soit qu’un occupant rencontré a refusé le pochage.<br> Si vous souhaitez faire fabriquer et poser une plaque émaillée avec votre numéro d'adresse et respecter la charte graphique officielle, nous vous préconisons de suivre les caractéristiques suivantes : <br>•	Hauteur des chiffres : 80 mm<br>•	Police employée : Arial, avec un crénage de 1cm.<br>•	Espacement : 0,5cm<br>•	Couleur des chiffres : blanc<br>•	Dimension du fond : hauteur de 90 mm et largeur suivant le nombre de chiffres du numéro<br>•	Couleur du fond : bleu roi<br>•	La distance entre le bord du cadre et le début du 1 er chiffre est de 2 cm, <br>•	Ceci en est de même pour le bord du cadre et la fin du dernier chiffre.  <br>•	La taille maxi d'un cadre de 4 chiffres doit être du côté de l'intérieur (30 cm de longueur, 10 cm de largeur bien centré dans le cadre). Puis du côté de l'extérieur (40 cm de longueur, 25 cm de largeur <br>•	Positionnement de la plaque à 2,20m à droite de votre entrée.
            `,
    },
    {
        question:
            "J’habite dans une cité déjà adressée. Est-ce que je vais conserver mon numéro et mon nom de rue existant ?",
        response: `Dans la mesure du possible nous reprenons l’existant. Dans ce cas l’entrée de la cité aura une adresse et l’adressage existant dans la cité sera conservé.
        Dans certains cas un nouvel adressage est réalisé en remplaçant les anciens noms de voie et les numéros d’adresse.`,
    },
    {
        question: "Comment bien utiliser la BD Adresse ?",
        response: `Des tutoriels existent sous forme de vidéos et peuvent être consultés`,
    },
    // {
    //     question:
    //         "Je n’ai pas d’adresse et je souhaiterais en avoir une. Comment faire et est-ce gratuit ?",
    //     response: `Vous pouvez faire une demande de nouvelle adresse en remplissant le formulaire sous l‘onglet Service de la BD Adresse. Votre demande sera traitée par la Centrale d’Adressage et l’adresse vous sera ensuite attribuée gratuitement.`,
    // },
    // {
    //     question: "Comment puis-je prouver avoir une certaine adresse ?",
    //     response: `Dans l’onglet Service de la BD Adresse il est possible de faire une demande de certificat en renseignant un formulaire compléter de pièces justificatives (justificatif de domicile, photo de l’entrée, etc…)`,
    // },
    // {
    //     question:
    //         "Peut-on signaler une erreur constatée sur une adresse et avoir la correction ?",
    //     response: `Dans l’onglet Service de la BD Adresse, il existe un formulaire pour signaler une erreur`,
    // },
    {
        question: "Comment savoir quel est le nom de ma rue ?",
        response: `Les noms de rue seront panneautés aux carrefours et également consultables sur la BD Adresse`,
    },
    {
        question: "Comment savoir quel est mon numéro d’adresse ?",
        response: `Le numéro d’adresse peut être matérialisé à votre entrée et consulté dans la BD Adresse.`,
    },
];
